<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios'
import Swal from "sweetalert2";
// import Swal from "sweetalert2";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Data Ability",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
    //   tableData: tableData,
      title: "Data Ability",
      items: [
        {
          text: "Master",
          href: "/"
        },
        {
          text: "Ability",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "var_ability", sortable: true },
        { key: "text_ability", sortable: true },
        'aksi'
      ],
      baseapi: localStorage.getItem("baseapi"),
      user: JSON.parse(localStorage.getItem('user')),
      dataAbility: [],
      dataAbilitys: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataAbility.length;
    }
  },
  mounted() {
    this.loaddata()
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    deleteData(id){
        axios
            .delete(
            this.baseapi+`api/ability/`+id,
            {
                headers: {
                    Authorization: `Bearer `+this.user.accessToken
                }
            }
            )
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil Dihapus',
                    showConfirmButton: false,
                    timer: 1500
                })
            this.loaddata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
            })
            return err;
        });
    },
    onSubmit(e) {
        e.preventDefault();
        this.$validator.validateAll();
        axios.post(this.baseapi+'api/ability', {
            var_ability: this.dataAbilitys.var_ability,
            text_ability: this.dataAbilitys.text_ability,
            keterangan: this.dataAbilitys.keterangan,
        },{
            headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer `+this.user.accessToken
                }
                })
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil Ditambah',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.loaddata()
                this.dataAbilitys= []

                // Wait until the models are updated in the UI
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-tambah')
                });
            return res;
            })
            .catch((err) => {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
            })
            return err;
        });
    },

    editData(id) {
        this.$validator.validateAll();
        axios
            .put(
            this.baseapi+`api/ability/${id}`,
            {
                var_ability: this.dataAbilitys.var_ability,
                text_ability: this.dataAbilitys.text_ability,
                keterangan: this.dataAbilitys.keterangan,
            }
            ,{
                headers:{
                Authorization: `Bearer `+this.user.accessToken
                }
            })
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil Diubah',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.dataAbilitys = []

                this.$nextTick(() => {
                    this.$bvModal.hide('modal-edit')
                })
            return res;
            })
            .catch((err) => {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: false,
                timer: 1500
            })
            return err;
        });
    },
    
    // loaddata user
    loaddata(){
        axios.get(this.baseapi+"api/ability", {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
            }
        }).then(response => {
            this.dataAbility = response.data.data;
        }).catch(error=>{
            console.log(error)
            return error
            });
    },

    // detail
    getDetail(id){
        axios.get(this.baseapi+"api/ability/"+id, {
            headers: {
                Authorization: `Bearer `+this.user.accessToken
            }
        }).then(response => {
            this.dataAbilitys = response.data.data;
            this.$nextTick(() => {
                this.$bvModal.show('modal-edit')
            });
        }).catch(error=>{
            console.log(error)
            return error
            });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Data Table</h4> -->
            <div class="row mt-4">
                <!-- <div class="form-group row">
              <div class="col-sm-6">
                <b-button variant="success" v-b-modal.modal-tambah
                  ><i class="fas fa-plus"></i> Tambah</b-button
                >
              </div>
              <div class="col-sm-6 text-end">
              </div>
            </div> -->
              <div class="col-sm-6 col-md-6">
                <b-button variant="success" size="sm" class="mb-2" v-b-modal.modal-tambah
                  ><i class="fas fa-plus"></i> Tambah</b-button
                >
              </div>
              <!-- <div class="col-sm-6 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div> -->
              <!-- Search -->
              <div class="col-sm-6 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="dataAbility"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template #cell(aksi)="data">
                  <a type="button" @click="getDetail(data.item.id)" data-toggle="tooltip" title="Edit" class="mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                  <a type="button"  @click="deleteData(data.item.id)" title="Delete" class="text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>
              </template>
            </b-table>
            </div>
            <div class="row">
            <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                </div>
                </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal tambah -->
    <b-modal
        body-class="bg-white"
        id="modal-tambah"
        scrollable
        title="Tambah Data"
        cancel-title="Close"
        ok-title="Accept"
        cancel-variant="outline-secondary"
      >
        <form
          ref="form"
          @submit.stop.prevent="onSubmit"
        >
            <b-form-group
                label="Var Ability"
                label-for="name"
                class="mb-3"
            >
                <b-form-input
                    v-validate="'required'"
                    name="var_ability"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('var_ability')}"
                    id="name"
                    v-model="dataAbilitys.var_ability"
                />
                <span class="text-danger" v-if="errors.has('var_ability')">
                    {{ errors.first('var_ability') }}
                    </span>
            </b-form-group>

            <b-form-group
                label="Text Ability"
                label-for="email"
                class="mb-3"
            >
                <b-form-input
                    v-validate="'required'"
                    name="durasi"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('durasi')}"
                    id="durasi"
                    v-model="dataAbilitys.text_ability"
                    type="text"
                />
                <span class="text-danger" v-if="errors.has('durasi')">
                    {{ errors.first('durasi') }}
                </span>
            </b-form-group>

            <b-form-group
                label="Keterangan"
                label-for="alamat"
                class="mb-3"
            >
                <b-form-textarea
                    name="keterangan"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('keterangan')}"
                    id="textarea"
                    v-model="dataAbilitys.keterangan"
                    rows="3"
                    max-rows="6"
                    v-validate="'required'"
                    ></b-form-textarea>
                <span class="text-danger" v-if="errors.has('keterangan')">
                    {{ errors.first('keterangan') }}
                </span>
            </b-form-group>      
        </form>
      

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              
              style="float:right"
              @click="onSubmit"
            >
            <i
              class="fa fa-floppy-o"/>
            
              Simpan
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- modal tambah -->
      <b-modal
        body-class="bg-white"
        id="modal-edit"
        scrollable
        title="Edit Data"
        cancel-title="Close"
        ok-title="Accept"
        cancel-variant="outline-secondary"
      >
        <form
          ref="form"
          @submit.stop.prevent="tambahdata"
        >
        <b-form-group
                label="Var Ability"
                label-for="name"
                class="mb-3"
            >
                <b-form-input
                    v-validate="'required'"
                    name="var_ability"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('var_ability')}"
                    id="name"
                    v-model="dataAbilitys.var_ability"
                />
                <span class="text-danger" v-if="errors.has('var_ability')">
                    {{ errors.first('var_ability') }}
                    </span>
            </b-form-group>

            <b-form-group
                label="Text Ability"
                label-for="email"
                class="mb-3"
            >
                <b-form-input
                    v-validate="'required'"
                    name="text_ability"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('text_ability')}"
                    id="text_ability"
                    v-model="dataAbilitys.text_ability"
                    type="text"
                />
                <span class="text-danger" v-if="errors.has('text_ability')">
                    {{ errors.first('text_ability') }}
                </span>
            </b-form-group>

            <b-form-group
                label="Keterangan"
                label-for="alamat"
                class="mb-3"
            >
                <b-form-textarea
                    name="keterangan"
                    :class="{ 'form-control': true, 'is-invalid': errors.has('keterangan')}"
                    id="textarea"
                    v-model="dataAbilitys.keterangan"
                    rows="3"
                    max-rows="6"
                    v-validate="'required'"
                    ></b-form-textarea>
                <span class="text-danger" v-if="errors.has('keterangan')">
                    {{ errors.first('keterangan') }}
                </span>
            </b-form-group>
        </form>
      

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              
              style="float:right"
              @click="editData(dataAbilitys.id)"
            >
            <i
              class="fa fa-floppy-o"/>
            
              Simpan
            </b-button>
          </div>
        </template>
      </b-modal>
  </Layout>
</template>